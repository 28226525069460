import React from 'react';
import { Container } from "./styles";
import { Row,Col } from 'react-bootstrap';
import Form from 'react-bootstrap/Form';
import Carousel from '../../component/Carousel'
import CatalogView from '../../component/CatalogView'




class MemberPage extends React.Component {
  state = {
    loading:"",
  };

  componentDidMount(){
  }

  render() {
    return (
        <>
        <Container>
            <div>
                <Carousel/>
                <CatalogView/>
            </div>
        </Container>
        </>
    );
  }
}

export default (MemberPage);