import React from 'react';
import { Container } from "./styles";
import { Row,Col,Table,InputGroup,FormControl } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';

import vip from '../../asset/images/vip.png';

import swal from 'sweetalert';

import { Switch,FormGroup,FormControlLabel,Chip   } from '@material-ui/core';

import { CircularProgressbar,buildStyles,CircularProgressbarWithChildren  } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';

import { easeQuadInOut } from 'd3-ease';
import AnimatedProgressProvider from "./AnimatedProgressProvider";
import ChangingProgressProvider from "./ChangingProgressProvider";
import RadialSeparators from "./RadialSeparators";




class BigWin extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false,
    status:"",
    url:"",
    urliframe:"/rootkub/index.html",
    checkedA: false,
    checkedB:false,
    checkedC: false,
    checkedD:false,
    percentage:0,
    p1:0,
    p2:0,
    p3:0,
    monny:'',
    bet:0,
    checksum:1,
    classNone:"classNone",
    Gogame:0,
    disabledGame:false,
    display1:"none-display",
    display2:"none-display",
    display3:"none-display",
    display4:"none-display",
  };

  
 componentDidMount(){

  

  }


  handleChange = (event) => {
    
    var name = event.target.name

     this.setState({[event.target.name]: event.target.checked });

    
    if(this.state.checkedA == false && name == "checkedA"){
      this.setState({display1:""})
    }
    else if(this.state.checkedA != false && name == "checkedA"){
      this.setState({display1:"none-display"})
    }




    if(this.state.checkedB == false && name == "checkedB"){
      this.setState({display2:""})
    }
    else if(this.state.checkedB != false && name == "checkedB"){
      this.setState({display2:"none-display"})
    }




    if(this.state.checkedC == false && name == "checkedC"){
      this.setState({display3:""})
    }
    else if(this.state.checkedC != false && name == "checkedC"){
      this.setState({display3:"none-display"})
    }




    if(this.state.checkedD == false && name == "checkedD"){
      this.setState({display4:""})
    }
    else if(this.state.checkedD != false && name == "checkedD"){
      this.setState({display4:"none-display"})
    }
    
  };





  async checkedB() {
    if(await this.state.checkedB == false){
      this.setState({p1:this.state.p1/3});
      this.setState({p2:this.state.p2/3});
      this.setState({p3:this.state.p3/10});
   }
   else{
      this.setState({p1:this.state.p1*3});
      this.setState({p2:this.state.p2*3});
      this.setState({p3:this.state.p3*10});
   }
  }


  async checkedC() {
    if(await this.state.checkedC == false){
      this.setState({classNone:"classNone"})
   }
   else{
    this.setState({classNone:""})
   }
  }

  async checkedD() {
    if(await this.state.checkedD == false){
      swal("ปิดการทำงานเสถียรภาพ", {
        icon: "warning",
        title: "ปิดการทำงาน",
        buttons: false,
        timer: 2000,
      });
   }
   else{
    swal("เปิดใช้งานเสถียรภาพสำเร็จ", {
      icon: "success",
      title: "เปิดการทำงาน",
      buttons: false,
      timer: 2000,
    });
   }
  }



CallEnterTokenKey = () =>{

  swal("กำลังประมวลผลการตั้งค่าทั้งหมด", {
    title: "ประมวลผล",
    buttons: false,
    timer: 3000,
  });

  setTimeout(() => {
    window.location.assign("https://lin.ee/a0A7vAf")
  }, 2000);

  // if(this.state.Gogame == 1){

  //   swal("กำลังประมวลผลการตั้งค่าทั้งหมด", {
  //     title: "ประมวลผล",
  //     buttons: false,
  //     timer: 3000,
  //   });

  //   if(sessionStorage.getItem("rootweb")=="www.###.com"){
  //     setTimeout(() => {
  //       window.location.assign(this.state.urliframe)
  //     }, 2000);
  //   }
  //   else{
  //     setTimeout(() => {
  //       window.location.assign("https://app.kub168.com/")
  //     }, 2000);
  //   }
  // }
  // else if(this.state.Gogame == 0){
  //   swal("แจ้งผู้ใช้บริการ", "โปรดเลือกค่ายเกมส์ที่ท่านต้องการ",{ dangerMode: true});
  // }
  // else{
  //   swal("แจ้งผู้ใช้บริการ", "ค่ายเกมส์ที่ท่านเลือกจะเปิดให้ใช้บริการเร็วๆนี้",{ dangerMode: true});
  // }
}

inputRegister = (e) =>{


  this.setState({ [e.target.name]: e.target.value });

  let monny = e.target.value
  let text = "";

  if(monny <= 0){
    text = "ไม่สามารถเล่นได้"
  }
  else if (monny <= 50){
    text = "1 - 5 บาท"
  }
  else if (monny <= 100){
    text = "5 - 10 บาท"
  }
  else if (monny <= 200){
    text = "10 - 20 บาท"
  }
  else if (monny <= 500){
    text = "20 - 30 บาท"
  }
  else if (monny <= 800){
    text = "30 - 40 บาท"
  }
  else if (monny <= 1000){
    text = "40 - 50 บาท"
  }
  else{
    text = "50 บาทขึ้นไป"
  }
  
  this.setState({bet:text})

}

vipline = () =>{
  window.open('https://lin.ee/z69lMzV','_blank');
}





iframeGame = () =>{
  this.setState({urliframe:this.state.url})
}


change_progressbar_input = () => {
  this.setState({percentage:50})
};

optionGame = (e) =>{
  var max = 20;
  var min = 1;
  this.setState({Gogame:e.target.value})
  if(e.target.value != 1){
    this.setState({
      p1:Math.floor(Math.random() * (15 - 0 + 1) ) + min,
      p2:Math.floor(Math.random() * (15 - 0 + 1) ) + min,
      p3:Math.floor(Math.random() * (5 - 0 + 1) ) + min,
      checkedA: false,
      checkedB:false,
      checkedC: false,
      checkedD:false,
      disabledGame:true,
    })
  }
  else{
    var max = 5;
    var min = 2;
    this.setState({
      p1:90 + Math.floor(Math.random() * (max - min + 1) ) + min,
      p2:82 + Math.floor(Math.random() * (max - min + 1) ) + min,
      p3:78 + Math.floor(Math.random() * (max - min + 1) ) + min,
      checkedA: true,
      checkedB:true,
      checkedC: false,
      checkedD:false,
      disabledGame:false,
    })
  }
}

  render() {
    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <h2>ปลดล็อคออโต้ เปิดหมดทุกฟังก์ชั่น</h2>
                    <Col sm={12}>
                        <Row>
                            <Col sm={12}>

                                {/* <Form.Select aria-label="เลือกค่ายเกมส์ที่ให้บริการ" onChange={this.optionGame}>
                                  <option>เลือกค่ายเกมส์ที่ให้บริการ</option>
                                  <option value="1">KUB168</option>
                                  <option value="2">OSKA789</option>
                                  <option value="3">FLIP789</option>
                                  <option value="4">JEDI68</option>
                                  <option value="5">UFA81</option>
                                  <option value="6">UFAUFO</option>
                                </Form.Select> */}
       
                                <br/>
                                
                                <FormGroup row>

                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedA} onChange={this.handleChange} name="checkedA" disabled={this.state.disabledGame} />}
                                    label="SCATTER x 10"
                                    size="lg"
                                  />
                                  <div className={this.state.display1}><Chip className='geen-box' label="100%" variant="outlined" /></div>
                                  
                                </FormGroup>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedB} onChange={this.handleChange} name="checkedB" disabled={this.state.disabledGame} />}
                                    label="WILD x 10"
                                  />
                                  <div className={this.state.display2}><Chip className='geen-box' label="100%" variant="outlined" /></div>
                                </FormGroup>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedC} onChange={this.handleChange} name="checkedC" disabled={this.state.disabledGame} />}
                                    label="MEGA WIN x 10"
                                  />
                                  <div className={this.state.display3}><Chip className='geen-box' label="100%" variant="outlined" /></div>
                                </FormGroup>
                                <FormGroup row>
                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedD} onChange={this.handleChange} name="checkedD" disabled={this.state.disabledGame} />}
                                    label="SUPER MEGA WIN x 10"
                                  />
                                  <div className={this.state.display4}><Chip className='geen-box' label="100%" variant="outlined" /></div>
                                </FormGroup>
                                <hr/>
                                {/* <div><img className='vip' src={vip} onClick={this.vipline}/></div> */}
                                {/* <FormGroup row>
                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedE} disabled={true} onChange={this.handleChange} name="checkedE" />}
                                    label="ปลดล็อคยูสทุกเว็บ"
                                  />
                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedF} disabled={true} onChange={this.handleChange} name="checkedF" />}
                                    label="ล้างประวัติทุกเว็บ"
                                  />
                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedF} disabled={true} onChange={this.handleChange} name="checkedG" />}
                                    label="ปิดการติดตามจากเว็บ"
                                  />
                                  <FormControlLabel
                                    control={<Switch checked={this.state.checkedF} disabled={true} onChange={this.handleChange} name="checkedG" />}
                                    label="ล้างติดโปร"
                                  />
                                </FormGroup> */}

    

                                {/* <div className='progress'>
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={this.state.p1}
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {(value) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}%`}
                                        styles={{
                                          root: {},
                                          path: {
                                            stroke: '#0078cd',
                                            strokeLinecap: 'butt',
                                            transition: 'stroke-dashoffset 0s ease 0s',
                                            transformOrigin: 'center center',
                                          },
                                          trail: {
                                            stroke: '#d6d6d6',
                                            strokeLinecap: 'butt',
                                            transformOrigin: 'center center',
                                          },
                                          text: {
                                            fill: '#0078cd',
                                            fontSize: '16px',
                                          },
                                          background: {
                                            fill: '#3e98c7',
                                          },
                                        }}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                </div> */}
                                {/* <div className='progress'>
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={this.state.p2}
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {(value) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}%`}
                                        styles={{
                                          root: {},
                                          path: {
                                            stroke: '#fd4800',
                                            strokeLinecap: 'butt',
                                            transition: 'stroke-dashoffset 0s ease 0s',
                                            transformOrigin: 'center center',
                                          },
                                          trail: {
                                            stroke: '#d6d6d6',
                                            strokeLinecap: 'butt',
                                            transformOrigin: 'center center',
                                          },
                                          text: {
                                            fill: '#fd4800',
                                            fontSize: '16px',
                                          },
                                          background: {
                                            fill: '#3e98c7',
                                          },
                                        }}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                </div> */}

                                {/* <div className='progress'>
                                <AnimatedProgressProvider
                                  valueStart={0}
                                  valueEnd={this.state.p3}
                                  duration={1.4}
                                  easingFunction={easeQuadInOut}
                                >
                                  {(value) => {
                                    const roundedValue = Math.round(value);
                                    return (
                                      <CircularProgressbar
                                        value={value}
                                        text={`${roundedValue}%`}
                                        styles={{
                                          root: {},
                                          path: {
                                            stroke: '#008c1e',
                                            strokeLinecap: 'butt',
                                            transition: 'stroke-dashoffset 0s ease 0s',
                                            transformOrigin: 'center center',
                                          },
                                          trail: {
                                            stroke: '#d6d6d6',
                                            strokeLinecap: 'butt',
                                            transformOrigin: 'center center',
                                          },
                                          text: {
                                            fill: '#008c1e',
                                            fontSize: '16px',
                                          },
                                          background: {
                                            fill: '#3e98c7',
                                          },
                                        }}
                                      />
                                    );
                                  }}
                                </AnimatedProgressProvider>
                                </div> */}
                                {/* <div></div>
                                <div className='progress-text'>อัตราการชนะ %</div>
                                <div className='progress-text'>Free Spin %</div>
                                <div className='progress-text'>แจ็คพอต %</div>
                                <br/>
                                <hr/> */}
                                <div className={this.state.classNone}>
                                <Form.Group className="mb-3" controlId="formGridAddress1">
                                  <div>จำนวนเงิน(บาท)</div>
                                  <Form.Control value={this.state.monny} placeholder="ใส่จำนวนเงิน" name="monny" onChange={this.inputRegister} type="number" disabled={this.state.lookinput} required={true}/>
                                  <div>Bet(บาท) อัตราประเมิน {this.state.bet}+-</div>
                                  <Form.Control value={this.state.bet +'+-'} placeholder="ใส่จำนวนเงิน" name="bet" onChange={this.inputRegister} disabled={true} required={true}/>
                                </Form.Group>.
                              <br/>
                                </div>
                                <Form className="fix-text-left" noValidate>
                                <Row>
                                  <Col sm={6}>
                                    <div className="app-login-group btn-login-c fix-center-bg"><div className="app-center-div" onClick={this.CallEnterTokenKey}>ยกเลิก <img className="fix-loading" src={this.state.loading}/></div></div>
                                  </Col>
                                  <Col sm={6}>
                                    <div className="app-login-group btn-login fix-center-bg"><div className="app-center-div" onClick={this.CallEnterTokenKey}>เริ่มการใช้งาน <img className="fix-loading" src={this.state.loading}/></div></div>
                                  </Col>
                                </Row>
                              </Form>
                              <br/>
                              <div><img className='vip' src="https://www.veniocrm.com/wp-content/uploads/2020/08/App-Store-Google-Play@2x-768x107.png"/></div>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (BigWin);