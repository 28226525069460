import React, { Component } from 'react';
import ReactDOM from 'react-dom';
import App from './components/App';
import './styles/app.scss';

export default class CatalogView extends Component {
    render() {
        return (
            <div>
                <App status={this.props.status}/>
            </div>
        );
    }
}