import React from 'react';
import { Route , withRouter} from 'react-router-dom';
import { withStyles } from "@material-ui/core/styles";

import EmojiEmotionsOutlinedIcon from '@material-ui/icons/EmojiEmotionsOutlined';
import PermIdentityOutlinedIcon from '@material-ui/icons/PermIdentityOutlined';
import HistoryOutlinedIcon from '@material-ui/icons/HistoryOutlined';
import FavoriteBorderOutlinedIcon from '@material-ui/icons/FavoriteBorderOutlined';
import AppsOutlinedIcon from '@material-ui/icons/AppsOutlined';
import LiveTvOutlinedIcon from '@material-ui/icons/LiveTvOutlined';

import { config } from "../configs/constant";
import axios from "axios";

const styles = theme => ({
  root: {
    width: '100%',
    position: 'fixed',
    bottom: 0,
    zIndex: 2000,
    backgroundColor: '#2d3436',
    backgroundColor: '#2876f9',
    backgroundImage: JSON.parse(localStorage.getItem("_profileSetting")).backgroundImage,
    maxWidth:'768px'
  },
  displayBox:{
    display: 'flex',
    paddingTop:'5px'
  },
  boxMenu:{
    textAlign:'center',
    width:'100%',
    color:'#ffffff'
  }
});

class Navigation extends React.Component {
  state = {
    value:'Recents'
  };

  handleChange = (e) => {
    this.setState({
      value:e
    })
  };

  componentDidMount(){
    this.GetEvent();
  }

  GoPromotion = () =>{
    this.props.history.push("/promotion");
  }

  GoProfile = () =>{
    this.props.history.push("/Profile");
  }

  GoHistory = () =>{
    this.props.history.push("/history");
  }

  GoPrivilege = () =>{
    this.props.history.push("/privilege");
  }

  GoEvent = () =>{
    this.props.history.push("/Event")
  }

  GoLiveGame = () =>{
    this.props.history.push("/LiveGame")
  }

  GetEvent = () => {
    const { classes } = this.props;
    axios({
        method: 'post',
        url: (`${config.url}/content/appSetting`),
        headers: {'Authorization': 'Bearer ' + window.localStorage.getItem("_token")},
    })
    .then(resp => {
    if(resp.data.status){
        if(resp.data.app.length > 0){
          console.log(resp.data.app)
            this.setState({isEvent:true})
        }
        else{
            this.setState({isEvent:false})
        }
      }
    })
    .catch((error) => {

    });
  }

  render() {
    const { classes } = this.props;
    return (
      <div className={classes.root}>
        <div  className={classes.displayBox}>
        <div className={classes.boxMenu} onClick={this.GoPrivilege}>
          <div><FavoriteBorderOutlinedIcon/></div>
          <div>สูตรเกมส์</div>
        </div>
        <div className={classes.boxMenu} onClick={this.GoLiveGame}>
          <div><LiveTvOutlinedIcon/></div>
          <div>Live เกมส์</div>
        </div>
        <div className={classes.boxMenu} onClick={this.GoPromotion}>
          <div><EmojiEmotionsOutlinedIcon/></div>
          <div>โปรโมชั่น</div>
        </div>
        {/* <div className={classes.boxMenu} onClick={this.GoHistory}>
          <div><HistoryOutlinedIcon/></div>
          <div>ประวัติ</div>
        </div> */}
        {
          this.state.isEvent == true ? (
          <div className={classes.boxMenu} onClick={this.GoEvent}>
            <div><AppsOutlinedIcon/></div>
            <div>กิจกรรม</div>
          </div>
          ):""
        }
        <div className={classes.boxMenu} onClick={this.GoProfile}>
          <div><PermIdentityOutlinedIcon/></div>
          <div>โปรไฟล์</div>
        </div>
        </div>
      </div>
    );
  }
}

export default withStyles(styles, { withTheme: true })(withRouter(Navigation));