import React, { Component } from 'react';
import MovieList from './MovieList';
import MovieService from '../../services/MovieService';

export default class Movies extends Component {

    constructor() {
        super();

        this.state = {
            movies: []
        };
    }

    componentDidMount() {
        if(this.props.status == true){
            this.setState(() => ({ movies: MovieService.getWallpaper() }));
        }
        else{
            this.setState(() => ({ movies: MovieService.getMovies() }));
        }
    }

    render() {
        return (
            <div className="container-fluid" style={{marginLeft: '-15px'}}>
                <div className="d-flex flex-row">                    
                    <div className="col-sm-12">
                        <MovieList movies={this.state.movies} status={this.props.status} />
                    </div>
                </div>
            </div>
        );
    }
}