import movies from './movies.json';
import wallpaper from './wallpaper.json';

export default class MovieService {
    static getMovies() {
        return movies ? movies : [];
    }
    static getWallpaper() {
        console.log(wallpaper)
        return wallpaper ? wallpaper : [];
    }
}