import React from 'react';
import { Container } from "./styles";
import { Row,Col,Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import logo from '../../asset/images/rootslot.png';

import swal from 'sweetalert';


class SendSMS extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false,
    tokenkey:"",
    mode:"",
  };

  
  componentDidMount(){

    this.CallGetSetting()

  }



CallRegister = () =>{
  if(this.state.register == true){
    this.setState({
      loading:loading,
      lookinput:true,
      bankList:''
    })
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/member");
    }, 2000);
  }
}



CallSetting = () =>{
  var querystring = require('querystring');
  if(this.state.tokenkey == ""){
    swal("เกิดข้อผิดพลาด", "กรุณาใส่ URL เกมส์สำหรับการเล่น",{ dangerMode: true});
    return
  }

  axios.post(`${config.url}/content/Setting`,
  querystring.stringify(
    {
      url:this.state.tokenkey,
    }
  ))
  .then(resp => {
    if(resp.data.status){
      swal("บันทึกสำเร็จ", {
        icon: "success",
        title: "สำเร็จ",
        buttons: false,
        timer: 2000,
      });
      this.CallGetSetting()
    }
    else{
      swal("เกิดข้อผิดพลาด", "บันทึกใหม่อีกครั้ง",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}



CallGetSetting = () =>{

  axios({
    method: 'get',
    url: (`${config.url}/content/GetSetting`),
  })
  .then(resp => {
    if(resp.data.status){
      this.setState({tokenkey:resp.data.app.detail})
      if(resp.data.app.setting == "1"){
        this.setState({mode:true})
      }
      else{
        this.setState({mode:false})
      }
    }
    else{
      swal("เกิดข้อผิดพลาด", "ไม่สามารถดึงค่าล่าสุดได้",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}



CallSettingOpen = () =>{

  axios({
    method: 'get',
    url: (`${config.url}/content/SettingOperater?id=1`),
  })
  .then(resp => {
    if(resp.data.status){
      swal("ทำการเปิดสำเร็จ", {
        icon: "success",
        title: "สำเร็จ",
        buttons: false,
        timer: 2000,
      });
      this.CallGetSetting()
    }
    else{
      swal("เกิดข้อผิดพลาด", "บันทึกใหม่อีกครั้ง",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}







inputForm = (e) =>{

  this.setState({ [e.target.name]: e.target.value });

}






  render() {
    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={12}>
                      <Row>
                        <Col sm={6}>
                        <Form className="fix-text-left">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>User</Form.Label>
                            <Form.Control type="text" placeholder="User THSMS" name="user" onChange={this.inputForm} value={this.state.user} />
                          </Form.Group>
                        </Form>
                        </Col>
                        <Col sm={6}>
                        <Form className="fix-text-left">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Pass</Form.Label>
                            <Form.Control type="text" placeholder="Pass THSMS" name="pass" onChange={this.inputForm} value={this.state.pass} />
                          </Form.Group>
                        </Form>
                        </Col>
                      </Row>

                      <Row>
                        <Col sm={6}>
                        <Form className="fix-text-left">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>Affiliation</Form.Label>
                            <Form.Control type="text" placeholder="Ex. osaka789" name="aff" onChange={this.inputForm} value={this.state.aff} />
                          </Form.Group>
                        </Form>
                        </Col>
                        <Col sm={6}>
                        <Form className="fix-text-left">
                          <Form.Group className="mb-3" controlId="exampleForm.ControlInput1">
                            <Form.Label>URL End port</Form.Label>
                            <Form.Control type="text" placeholder="Ex. url www.xxxx.com" name="pass" onChange={this.inputForm} value={this.state.endpoint} />
                          </Form.Group>
                        </Form>
                        </Col>
                      </Row>
                        <Row>
                            <Col sm={12}>
                              <Form className="fix-text-left" noValidate>
                              <Form.Group className="mb-3" controlId="formGridAddress1">
                              <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>SMS Text (80)</Form.Label>
                                  <Form.Control as="textarea" rows={3} name="text" onChange={this.inputForm} value={this.state.text} />
                                </Form.Group>
                              </Form>
                                </Form.Group>
                              </Form>
                            </Col>

                            <Col sm={6}>
                              <Form className="fix-text-left" noValidate>
                              <Form.Group className="mb-3" controlId="formGridAddress1">
                              <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>เบอร์มือถือ</Form.Label>
                                  <Form.Control as="textarea" rows={3} />
                                </Form.Group>
                              </Form>

                                </Form.Group>
                              </Form>
                            </Col>
                            <Col sm={6}>
                              <Form className="fix-text-left" noValidate>
                              <Form.Group className="mb-3" controlId="formGridAddress1">
                              <Form>
                                <Form.Group className="mb-3" controlId="exampleForm.ControlTextarea1">
                                  <Form.Label>เบอร์มือถือ</Form.Label>
                                  <Form.Control as="textarea" rows={3} />
                                </Form.Group>
                              </Form>

                                </Form.Group>
                              </Form>
                            </Col>
                        </Row>
                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (SendSMS);