import React from 'react';
import { Container } from "./styles";
import { Row,Col,Button } from 'react-bootstrap';
import Form from 'react-bootstrap/Form'
import { config } from "../../configs/constant";

import axios from "axios";


import jwt_decode from "jwt-decode";

import loading from '../../asset/images/neeyai/loading.gif';
import logo from '../../asset/images/rootslot.png';

import swal from 'sweetalert';


class LoykrathongFlip extends React.Component {
  state = {
    loading:false,
    imageProfile:"",
    imageProfileDef : "https://social-tournaments.s3.eu-central-1.amazonaws.com/wp-content/uploads/2020/07/09115428/Street-Racer%E2%84%A2-Medium-Logo%402x-300x193.png",
    loading:"",
    lookinput:false,
    register:false,
    BankName:"",
    BankNo:"",
    displayname:"",
    email:"",
    validated:false,
    tokenkey:"",
    mode:"",
  };

  
  componentDidMount(){

    this.CallGetSetting()

  }



CallRegister = () =>{
  if(this.state.register == true){
    this.setState({
      loading:loading,
      lookinput:true,
      bankList:''
    })
    setTimeout(() => {
      this.setState({loading:true});
      this.props.history.push("/member");
    }, 2000);
  }
}



CallSetting = () =>{
  var querystring = require('querystring');
  if(this.state.tokenkey == ""){
    swal("เกิดข้อผิดพลาด", "กรุณาใส่ URL เกมส์สำหรับการเล่น",{ dangerMode: true});
    return
  }

  axios.post(`${config.url}/content/Setting`,
  querystring.stringify(
    {
      url:this.state.tokenkey,
    }
  ))
  .then(resp => {
    if(resp.data.status){
      swal("บันทึกสำเร็จ", {
        icon: "success",
        title: "สำเร็จ",
        buttons: false,
        timer: 2000,
      });
      this.CallGetSetting()
    }
    else{
      swal("เกิดข้อผิดพลาด", "บันทึกใหม่อีกครั้ง",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}



CallGetSetting = () =>{

  axios({
    method: 'get',
    url: (`${config.url}/content/GetSetting`),
  })
  .then(resp => {
    if(resp.data.status){
      this.setState({tokenkey:resp.data.app.detail})
      if(resp.data.app.setting == "1"){
        this.setState({mode:true})
      }
      else{
        this.setState({mode:false})
      }
    }
    else{
      swal("เกิดข้อผิดพลาด", "ไม่สามารถดึงค่าล่าสุดได้",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}



CallSettingOpen = () =>{

  axios({
    method: 'get',
    url: (`${config.url}/content/SettingOperater?id=1`),
  })
  .then(resp => {
    if(resp.data.status){
      swal("ทำการเปิดสำเร็จ", {
        icon: "success",
        title: "สำเร็จ",
        buttons: false,
        timer: 2000,
      });
      this.CallGetSetting()
    }
    else{
      swal("เกิดข้อผิดพลาด", "บันทึกใหม่อีกครั้ง",{ dangerMode: true});
      return
    }
  })
  .catch((error) => {
    swal("เกิดข้อผิดพลาด", "กรุณาติดต่อเจ้าหน้าที่",{ dangerMode: true});
    return
  });
}







inputForm = (e) =>{

  this.setState({ [e.target.name]: e.target.value });

}






  render() {
    return (
        <>
        {/* <video autoPlay={true} muted loop id="myVideo">
            <source src={vdo} type="video/mp4"/>
        </video> */}
        <Container>
          <div className='top-bar'>
            <img className='img-c-t' src="https://flip789.com/g_assets/img/site/2022/04/01-19_14_06-FLIP789.webp"/>
          </div>
          <div className='bt-bar'></div>
          {/* <div className='top-bar-img'></div> */}
            <div className="app-conten">
                <Row className="fix-center">
                    {/* <Col sm={4}><img className="fix-img" src={anime}/></Col> */}
                    <Col sm={12}>
                      <Row>
                      <iframe src="https://theoldsiam.co.th/loykrathong" title="ลอยกระทงออนไลน์"></iframe>
                      </Row>


                    </Col>
                </Row>
            </div>
        </Container>
        </>
    );
  }
}

export default (LoykrathongFlip);