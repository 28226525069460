import React from 'react';
import { withStyles } from "@material-ui/core/styles";
import ArrowForwardIosIcon from '@material-ui/icons/ArrowForwardIos';
import TextField from '@material-ui/core/TextField';

import Logo from "../asset/images/OSAKA789.png";

import FadeIn from "react-fade-in";
import Lottie from "react-lottie";
import LoadingData from "../Loading.json";
import SuccessData from "../Success.json";

import axios from "axios";
import { config } from "../configs/constant";

import vdo from '../asset/vdo/monny.mp4';
import { Player } from 'video-react';
import '../asset/css/vdo.css';

const AppSetting = JSON.parse(localStorage.getItem("_profileSetting"));

const styles = theme => ({
  inputbox: {
    '& > *': {
      margin: theme.spacing(1),
      width: '25ch',
      backgroundColor: '#ffffff',
    },
  },
  icon: {
    marginRight: theme.spacing(2),
  },
  disabel:{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
    backgroundColor: '#a1a1a1',
  },
  PageContent: {
    backgroundColor: '#000000',
    height:'100vh'
  },
  PageContentloading:{
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    width: '100%'
  },
  LogImage :{
    width:'100%'
  },
  button :{
    maxWidth: '560px',
    minHeight: '50px',
    textAlign: 'center',
    lineHeight: '50px',
    fontSize:'25px',
    borderRadius:'100px',
    margin:'50px auto',
    backgroundColor: '#ffffff',
    backgroundColor: '#2876f9',
    color:'#ffffff'
  },
  texterror:{
    color:'#fbb800'
  },
  footer: {
    backgroundColor: theme.palette.background.paper,
    padding: theme.spacing(6),
  },
});

const LoadingOptions = {
  loop: true,
  autoplay: true,
  animationData: LoadingData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};
const SuccessOptions = {
  loop: false,
  autoplay: true,
  animationData: SuccessData,
  rendererSettings: {
    preserveAspectRatio: "xMidYMid slice"
  }
};


class ViewEvent extends React.Component {
  state = {
    loading:false,
    mobileNo :"",
    pin:"",
    class: false,
    profileSetting:"",
    errorText:"สมัครสมาชิก",
  };


  componentDidMount(){
    this.setState({profileSetting:JSON.parse(localStorage.getItem("_profileSetting"))})
  }

  RegisterNow = () =>{
    window.location.assign('https://bit.ly/3zt0a9O')
  }

  InputLogin = (e) =>{

    this.setState({
      [e.target.name]:e.target.value
    })

    const regex = /^\(?([0-9]{3})\)?[-. ]?([0-9]{3})[-. ]?([0-9]{4})$/;
    if(regex.test(this.state.mobileNo) && this.state.pin.length == 3){
      this.setState({class:true})
    }
    else{
      this.setState({class:false})
    }
  }

  render() {
    const { classes } = this.props;
    return (
      <>
          <div id="fadein" className={classes.PageContent}>
          <main className="fix-m-landing w-100">

          {/* <video width="100%"autoPlay={true} controls >
                <source src={vdo} type="video/mp4"/>
          </video> */}

{/*             
          <Player autoPlay={true}>
            <source src={vdo} />
          </Player> */}

{/* 
            <video autoPlay={true} loop id="myVideo2">
              <source src={vdo} type="video/mp4"/>
            </video> */}

          <iframe className='iframe-2' src="https://www.youtube.com/embed/YG6PzfL1r9Y" title="YouTube video player" frameborder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture" allowfullscreen></iframe>

              <div className={classes.button} onClick={this.RegisterNow}>
                ลงทะเบียน
                <ArrowForwardIosIcon/>
              </div>
              {/* <a href={`${JSON.parse(localStorage.getItem("_profileSetting")).regisRef}`}><center className={classes.texterror}>{this.state.errorText}</center></a> */}
              <br/>
          </main>
          </div>
      </>
    );
  }
}

export default withStyles(styles, { withTheme: true })(ViewEvent);