import React, { Component } from "react";
import { Carousel } from 'react-bootstrap';


import CarouselCSS from "./CSS";

class carousel extends Component {

  state = {
    index:0,
  };

  handleSelect = (selectedIndex, e) => {
    this.setState({index:selectedIndex})
  };

  render() {
    if(this.props.cover != null){
      return (
        <Carousel fade  activeIndex={this.state.index} onSelect={this.handleSelect}>
          <Carousel.Item>
          <img
            className="d-block w-100"
            src={this.props.cover}
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>{this.props.title}</h3>
            <p>{this.props.detail}</p>
          </Carousel.Caption>
        </Carousel.Item>
        </Carousel>
      );
    }
    else{
      return (
        <Carousel fade  activeIndex={this.state.index} onSelect={this.handleSelect}>
          <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.novelsaur.com/wp-content/uploads/2021/09/ไอ้ตัวร้ายกับนายตัวเร็กซ์-banner.jpg"
            alt="First slide"
          />
          <Carousel.Caption>
            <h3>First slide label</h3>
            <p>Nulla vitae elit libero, a pharetra augue mollis interdum.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.novelsaur.com/wp-content/uploads/2021/05/รักไม่รับจ้าง-banner.jpg"
            alt="Second slide"
          />
  
          <Carousel.Caption>
            <h3>Second slide label</h3>
            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit.</p>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src="https://www.novelsaur.com/wp-content/uploads/2021/05/พ่อหมออลเวง-banner.jpg"
            alt="Third slide"
          />
  
          <Carousel.Caption>
            <h3>Third slide label</h3>
            <p>
              Praesent commodo cursus magna, vel scelerisque nisl consectetur.
            </p>
          </Carousel.Caption>
        </Carousel.Item>
  
        </Carousel>
      );
    }
  }
}

export default carousel;

