import React from 'react';
import PropTypes from 'prop-types';
import ReactStars from "react-rating-stars-component";


const styles = {
    starsOuter: {
        overflow: 'hidden'
    },
    star: {
        padding: '1px'
    }
};




const StarRating = (props) => {

    const firstExample = {
        size: 30,
        value: props.rating,
        edit: false
      };

    return (
        <div>
            <ReactStars {...firstExample} />
        </div>
    );
};


export default StarRating;